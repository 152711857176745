:global {
  .input-inline-api {
    position: relative;
    .text-input-value {
      cursor: pointer;
      padding: 0.5rem;
      &:hover {
        background-color: rgb(211, 210, 210);
      }
    }
    .ant-btn {
      box-shadow: 0px 3px 0.5rem rgba(167, 160, 137, 0.4);
      &:hover {
        color: #000000;
      }
      &.ant-btn-default {
        background-color: #f0f0f0;
        border-color: #e2e2e2;

        &:hover {
          background-color: #e2e2e2;
          border-color: #e2e2e2;
        }
      }
    }
  }
}
